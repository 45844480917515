<template>
   <div class="wrapper">

      <!-- Navbar -->
      <Navbar/>
      <!-- /.navbar -->

      <!-- Main Sidebar Container -->
      <Sidebar/>
      <!-- /.main Sidebar Container -->

      <!-- Page content -->
      <router-view/>
      <!-- /.page content -->

      <!-- Footer -->
      <Footer/>
      <!-- /.footer -->
   </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Navbar from "./components/Navbar.vue";
import Sidebar from "./components/Sidebar.vue";

export default {
   'name': 'Dashboard',
   components: {Sidebar, Navbar, Footer},
   data(){
      return {
         user: {
            full_name: ''
         },
         user_image: '/static/dist/img/user2-160x160.jpg'
      }
   },
   methods: {
      logout(e){
         Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
         }).then((result) => {
            if (result.isConfirmed) {
               // logout
               this.$session.clear();
               localStorage.clear();
               Swal.fire(
                  'Logged Out!',
                  'You have been logged out.',
                  'success'
               )
               setTimeout(function () {
                  window.location.href = '/login';
               }, 2000);
            }
         })
      }
   },
   mounted(){
      document.querySelector('body').className = "hold-transition sidebar-mini layout-fixed accent-primary layout-navbar-fixed layout-footer-fixed"
   },
   watch: {
      'user.user_image': {
         handler(user_image){
            this.user_image = this.$remoteUrl+user_image;
         },
         deep: true
      }
   }
}
</script>
