<template>
    <footer class="main-footer">
      <strong>Copyright &copy; 2023.</strong> Desenvolvido por WCSA.
      <div class="float-right d-none d-sm-inline-block">
        <b>Version</b> 1.0
      </div>
    </footer>

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
</template>
