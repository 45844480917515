<template>
   <nav class="main-header navbar navbar-expand navbar-white navbar-light bg-gradient-gray">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
         <li class="nav-item">
            <router-link class="nav-link" data-widget="pushmenu" to="#" role="button"><i class="fas fa-bars"></i></router-link>
         </li>
      </ul>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
         <!-- Notifications Dropdown Menu -->
         <li class="nav-item dropdown">
            <router-link class="nav-link" data-toggle="dropdown" to="#">
               <i class="far fa-bell"></i>
               <span class="badge badge-warning navbar-badge">15</span>
            </router-link>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
               <span class="dropdown-item dropdown-header">15 Notificações</span>
               <div class="dropdown-divider"></div>
               <router-link to="#" class="dropdown-item">
                  <i class="fas fa-envelope mr-2"></i> 4 novas mensagens
                  <span class="float-right text-muted text-sm">3 mins</span>
               </router-link>
               <div class="dropdown-divider"></div>
               <router-link to="#" class="dropdown-item">
                  <i class="fas fa-users mr-2"></i> 8 novos usuários
                  <span class="float-right text-muted text-sm">12 hours</span>
               </router-link>
               <div class="dropdown-divider"></div>
               <div class="dropdown-divider"></div>
               <router-link to="#" class="dropdown-item dropdown-footer">Ver todas as notificações</router-link>
            </div>
         </li>
         <li class="nav-item dropdown">
            <router-link class="nav-link" data-toggle="dropdown" to="#">
               <i><div class="media pb-3">
                  <img src="/static/dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-32 mr-2 img-circle">
               </div></i>
               <!-- <i class="far fa-user fa-2x"></i> -->
               <!-- <span class="badge badge-danger navbar-badge">3</span> -->
            </router-link>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
               <router-link to="#" class="dropdown-item">
                  <!-- Message Start -->
                  <div class="media">
                     <div class="media-body">
                        <h3 class="dropdown-item-title text-center">
                           <router-link to="/profile" class="text-center">Perfil</router-link>
                        </h3>
                     </div>
                  </div>
                  <!-- Message End -->
               </router-link>
               <div class="dropdown-divider"></div>
               <router-link to="#" class="dropdown-item">
                  <!-- Message Start -->
                  <div class="media">
                     <div class="media-body">
                        <h3 class="dropdown-item-title text-center">
                           <p @click.prevent="logout" class="text-center">Sair</p>
                        </h3>
                     </div>
                  </div>
                  <!-- Message End -->
               </router-link>
               <div class="dropdown-divider"></div>
            </div>
         </li>
      </ul>
   </nav>
</template>

<script>
export default {
   name: "Navbar"
}
</script>

