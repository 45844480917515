<template>
   <aside class="main-sidebar sidebar-light-primary elevation-4">
      <!-- Brand Logo -->
      <router-link to="/" class="brand-link bg-gradient-gray">
         <img src="/static/dist/img/longavida.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
         <span class="brand-text font-weight-light "> <strong>Vida Longa</strong> </span>
      </router-link>

      <!-- Sidebar -->
      <div class="sidebar">

         <!-- Sidebar Menu -->
         <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
               <!-- Add icons to the links using the .nav-icon class
                    with font-awesome or any other icon font library -->
               <li class="nav-item menu-open">
                  <router-link to="/dashboard" class="nav-link bg-gradient-gray">
                     <i class="nav-icon fas fa-tachometer-alt"></i>
                     <p>
                        Dashboard
                        <!-- <i class="right fas fa-angle-left"></i> -->
                     </p>
                  </router-link>

               </li>
               <li class="nav-item">
                  <router-link :to="{name: 'articles'}" class="nav-link">
                     <i class="nav-icon fas fa-file-invoice"></i>
                     <p> Artigos </p>
                  </router-link>
               </li>
               <li class="nav-item">
                  <router-link :to="{name: 'users'}" class="nav-link">
                     <i class="nav-icon fas fa-users"></i>
                     <p> Usuários </p>
                  </router-link>
               </li>
            </ul>
         </nav>
         <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
   </aside>
</template>

<script>
export default {
   name: "Sidebar"
}
</script>



